import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ProjStyle from './css/projects.module.css';
import YoutubeShortsSVG from './assets/logos/youtube_shorts.svg';
import EcommerceSVG from './assets/logos/shopping-cart.svg';
import projects from './assets/projects.json';

export default function Projects() {
  const { projectName } = useParams();
  const [isDefault, setIsDefault] = useState(true);
  useEffect(() => {
    if (projectName) setIsDefault(false);
  }, [projectName]);
  switch (projectName) {
    case 'youtubeshorts':
      return (
        <div className={ProjStyle['proj1Layout']}>
          <div className={ProjStyle['proj1Display']}></div>
          <div className={ProjStyle['proj1Text']}>
            <h1 className={ProjStyle['proj1Text__title']}>Serveur proxy Youtube shorts</h1>
            <p className={ProjStyle['proj1Text__content']}>
              {`Une application simple qui envoie des requêtes vers l'API de youtube en passant par un proxy qui trie les
              vidéos "shorts" puis les renvoie au client. Fait avec Vue.js pour la partie client et node.js/Express.js
              pour le serveur.`}
            </p>
          </div>
        </div>
      );
      break;
    case 'ecommerce':
      return (
        <div className={ProjStyle['proj2Layout']}>
          <div className={ProjStyle['proj2Display']}></div>
          <div className={ProjStyle['proj2Text']}>
            <h1 className={ProjStyle['proj1Text__title']}>E-commerce</h1>
            <p className={ProjStyle['proj1Text__content']}>
              {`Une application de e-commerce avec une base de données, comptes utilisateurs, historique de commandes et un tableau de bord personnalisable.
              Développé avec React, Node.js/Express.js/PHP/MySQL/MongoDB et Stripe pour les paiements sécurisés.`}
            </p>
          </div>
        </div>
      );
      break;
    default:
      return (
        <div className={ProjStyle['project--layout']}>
          {projects.map((project, index) => {
            return (
              <div key={index} className={ProjStyle['project-card']}>
                <Link to={`../projects/${project.name}`}>
                  <div className={ProjStyle['project-card--thumbnail']}>
                    {project.name === 'youtubeshorts' ? (
                      <img src={YoutubeShortsSVG} alt={project?.alt} width={100} height={100} />
                    ) : (
                      <img src={EcommerceSVG} alt={project?.alt} width={100} height={100} />
                    )}
                  </div>
                  <p>{project?.desc}</p>
                </Link>
              </div>
            );
          })}
        </div>
      );
  }
}
