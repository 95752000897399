import ExpStyle from './css/experience.module.css';
import expFile from './assets/experience.json';

export default function Experience() {
  return (
    <div className={ExpStyle['expLayout']}>
      <main className={ExpStyle['timeline']}>
        {expFile &&
          expFile.exp.map((exp, index) => (
            <div
              className={
                index % 2 === 0
                  ? `${ExpStyle['container']} ${ExpStyle['left']}`
                  : `${ExpStyle['container']} ${ExpStyle['right']}`
              }
              key={index}>
              <div className={ExpStyle['content']}>
                <h2 className={ExpStyle['']}>{exp.h2}</h2>
                <p className={ExpStyle['']}>{exp.p}</p>
              </div>
            </div>
          ))}
      </main>
    </div>
  );
}
