import { Link, Outlet } from 'react-router-dom';
import HeaderStyle from './css/header.module.css';

export default function Header() {
  return (
    <header>
      <nav className={`${HeaderStyle['headerSection']} ${HeaderStyle['layout']}`}>
        <Link to='/' className={HeaderStyle['layout__link']}>
          <span className={HeaderStyle['layout__homeButton']}>Dylan Richardson</span>
        </Link>
        <Link to='/projects/default' className={HeaderStyle['layout__link']}>
          Projets
        </Link>
        <Link to='/experience' className={HeaderStyle['layout__link']}>
          Expérience
        </Link>
      </nav>
      <Outlet />
    </header>
  );
}
