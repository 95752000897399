import MainStyle from './css/main.module.css';
import CPLUSPLUSSVG from './assets/logos/c-plusplus.svg';
import CSHARPSVG from './assets/logos/c-sharp.svg';
import CSVG from './assets/logos/c.svg';
import JavascriptSVG from './assets/logos/javascript.svg';
import MongoDBSVG from './assets/logos/mongodb.svg';
import MySQLSVG from './assets/logos/mysql.svg';
import NodeJSSVG from './assets/logos/nodejs.svg';
import NextJSSVG from './assets/logos/nextjs-icon.svg';
import ReactJSSVG from './assets/logos/react.svg';
import ReduxSVG from './assets/logos/redux.svg';
import TailwindCSSSVG from './assets/logos/tailwindcss-icon.svg';
import VueSVG from './assets/logos/vue.svg';
import YoutubeShortsSVG from './assets/logos/youtube_shorts.svg';
import ShoppingCart from './assets/logos/shopping-cart.svg';
import GitHub from './assets/logos/github-icon.svg';
import Linkedin from './assets/logos/linkedin-icon.svg';
import PHPSVG from './assets/logos/php.svg';
import JQUERYSVG from './assets/logos/jquery.svg';
import HTML5SVG from './assets/logos/html-5.svg';
import { Link } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';

export default function Main() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [menuFrontend, setMenuFrontend] = useState(false);
  const [menuBackend, setMenuBackend] = useState(false);
  const [menuLanguages, setMenuLanguages] = useState(false);
  const [menuProjects, setMenuProjects] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const frontendSection = useRef(null);
  const backendSection = useRef(null);
  const languagesSection = useRef(null);
  const projectsSection = useRef(null);
  const layoutRef = useRef(null);
  const logoWidth = 100,
    logoHeight = 100;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 300) {
        setIsScrolled(true);
      } else setIsScrolled(false);
    });
    if (layoutRef?.current) {
      //  console.log(document.body.style);
      // window.addEventListener('mousemove', (elementRef) => {
      // console.log('lol');
      // elementRef.current.style.backgroundPositionX = -elementRef.offsetX + 'px';
      // elementRef.current.style.backgroundPositionY = -elementRef.offsetY + 'px';
      //   });
    }
  }, [isScrolled]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const smoothScroll = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    });
  };
  return (
    <main className={MainStyle['mainLayout']}>
      {isScrolled && (
        <button onClick={() => scrollToTop()} className={MainStyle['scroll-top-button']}>
          {'^'}
        </button>
      )}
      <div ref={layoutRef} className={MainStyle['homeLayout']}>
        <div className={MainStyle['name-section']}>
          <h2 className={MainStyle['name']}>Dylan Richardson</h2>
          <h3 className={MainStyle['job']}>Développeur Full Stack</h3>
          <span
            className={
              menuFrontend
                ? `${MainStyle['anchor-menu']} ${MainStyle['anchor-menu__hover']}`
                : `${MainStyle['anchor-menu']}`
            }
            onMouseOver={() => setMenuFrontend(true)}
            onMouseLeave={() => setMenuFrontend(false)}
            onClick={(e) => smoothScroll(frontendSection)}>
            {'Front-end'}
          </span>
          <span
            className={
              menuBackend
                ? `${MainStyle['anchor-menu']} ${MainStyle['anchor-menu__hover']}`
                : `${MainStyle['anchor-menu']}`
            }
            onMouseOver={() => setMenuBackend(true)}
            onMouseLeave={() => setMenuBackend(false)}
            onClick={(e) => smoothScroll(backendSection)}>
            {'Back-end'}
          </span>
          <span
            className={
              menuLanguages
                ? `${MainStyle['anchor-menu']} ${MainStyle['anchor-menu__hover']}`
                : `${MainStyle['anchor-menu']}`
            }
            onMouseOver={() => setMenuLanguages(true)}
            onMouseLeave={() => setMenuLanguages(false)}
            onClick={(e) => smoothScroll(languagesSection)}>
            {'Langages'}
          </span>
          <span
            className={
              menuProjects
                ? `${MainStyle['anchor-menu']} ${MainStyle['anchor-menu__hover']}`
                : `${MainStyle['anchor-menu']}`
            }
            onMouseOver={() => setMenuProjects(true)}
            onMouseLeave={() => setMenuProjects(false)}
            onClick={(e) => smoothScroll(projectsSection)}>
            {'Projets'}
          </span>
          <span className={MainStyle['anchor-menu-socials']}>
            <Link to='https://www.linkedin.com/in/dylan-richardson-895b9b21a/'>
              <img src={Linkedin} width={32} height={32} />
            </Link>
            <Link to='https://github.com/dyrich'>
              <img src={GitHub} width={32} height={32} />
            </Link>
          </span>
        </div>
      </div>
      <div className={MainStyle['introLayout']}>
        <div ref={frontendSection} className={`${MainStyle['section']} ${MainStyle['frontEndLayout']}`}>
          <h3 className={MainStyle['frontEndTitle']}>Front-End</h3>
          <div className={MainStyle['frontEndContent']}>
            <div className={MainStyle['frontEndContent--framework']}>
              <img src={ReactJSSVG} alt='ReactJS' title='ReactJS' width={logoWidth} height={logoHeight} />
              <span>ReactJS</span>
            </div>
            <div className={MainStyle['frontEndContent--framework']}>
              <img src={ReduxSVG} alt='Redux' title='Redux' width={logoWidth} height={logoHeight} />
              <span>Redux</span>
            </div>
            <div className={MainStyle['frontEndContent--framework']}>
              <img src={VueSVG} alt='Vue.js' title='Vue.js' width={logoWidth} height={logoHeight} />
              <span>Vue.js</span>
            </div>
            <div className={MainStyle['frontEndContent--framework']}>
              <img src={NextJSSVG} alt='NextJS' title='NextJS' width={logoWidth} height={logoHeight} />
              <span>NextJS</span>
            </div>
            <div className={MainStyle['frontEndContent--framework']}>
              <img src={TailwindCSSSVG} alt='TailwindCSS' title='TailwindCSS' width={logoWidth} height={logoHeight} />
              <span>TailwindCSS</span>
            </div>
            <div className={MainStyle['frontEndContent--framework']}>
              <img src={JQUERYSVG} alt='jQuery' title='jQuery' width={logoWidth} height={logoHeight} />
              <span>jQuery</span>
            </div>
          </div>
        </div>
        <div ref={backendSection} className={`${MainStyle['section']} ${MainStyle['backEndLayout']}`}>
          <h3 className={MainStyle['backEndTitle']}>Back-End</h3>
          <div className={MainStyle['backEndContent']}>
            <div className={MainStyle['backEndContent--logo']}>
              <img src={NodeJSSVG} alt='NodeJS' title='NodeJS' width={logoWidth * 1.5} height={logoHeight * 1.5} />
            </div>
            <div className={MainStyle['backEndContent--logo']}>
              <img src={MongoDBSVG} alt='MongoDB' title='MongoDB' width={logoWidth * 1.5} height={logoHeight * 1.5} />
            </div>
            <div className={MainStyle['backEndContent--logo']}>
              <img src={MySQLSVG} alt='MySQL' title='MySQL' width={logoWidth * 1.5} height={logoHeight * 1.5} />
            </div>
          </div>
        </div>
        <div ref={languagesSection} className={`${MainStyle['section']} ${MainStyle['languagesLayout']}`}>
          <h3 className={MainStyle['languagesTitle']}>Langages</h3>
          <div className={MainStyle['languagesContent']}>
            <div className={MainStyle['frontEndContent--framework']}>
              <img src={HTML5SVG} alt='HTML5' title='HTML5' width={logoWidth} height={logoHeight} />
              <span>HTML 5</span>
            </div>
            <div className={MainStyle['languagesContent--language']}>
              <img src={JavascriptSVG} alt='Javascript' title='Javascript' width={logoWidth} height={logoHeight} />
              <span>Javascript</span>
            </div>
            <div className={MainStyle['languagesContent--language']}>
              <img src={PHPSVG} alt='PHP' title='PHP' width={logoWidth} height={logoHeight} />
              <span>PHP</span>
            </div>
            <div className={MainStyle['languagesContent--language']}>
              <img src={CSVG} alt='C' title='C' width={logoWidth} height={logoHeight} />
              <span>Langage C</span>
            </div>
            <div className={MainStyle['languagesContent--language']}>
              <img src={CPLUSPLUSSVG} alt='C++' title='C++' width={logoWidth} height={logoHeight} />
              <span>C++</span>
            </div>
            <div className={MainStyle['languagesContent--language']}>
              <img src={CSHARPSVG} alt='C#' title='C#' width={logoWidth} height={logoHeight} />
              <span>C#</span>
            </div>
          </div>
        </div>
      </div>
      <div ref={projectsSection} className={`${MainStyle['projectsLayout']}`}>
        <h3 className={MainStyle['projectsTitle']}>Projets</h3>
        <div className={MainStyle['projectsContent']}>
          <div className={`${MainStyle['project']} ${MainStyle['section']}`}>
            <h4>Recherche Youtube shorts</h4>
            <div className={MainStyle['youtubeshorts-img']}>
              <Link to='/projects/youtubeshorts' state={{ query: 'youtubeshorts' }}>
                <img src={YoutubeShortsSVG} alt='logo Youtube shorts' width={logoWidth} height={logoHeight} />
              </Link>
            </div>
          </div>
          <div className={`${MainStyle['project']} ${MainStyle['section']}`}>
            <h4>E-commerce</h4>
            <div className={MainStyle['shopping-cart-img']}>
              <Link to='/projects/ecommerce' state={{ query: 'ecommerce' }}>
                <img src={ShoppingCart} alt='shopping cart' width={logoWidth} height={logoHeight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
