import { Route, Routes } from 'react-router-dom';
import Header from './Header';
import Main from './Main';
import Projects from './Projects';
import Experience from './Experience';
import AppStyle from './css/app.module.css';

export default function App() {
  return (
    <div className={AppStyle['layout']}>
      <Routes>
        <Route path='/' element={<Header />}>
          <Route index element={<Main />} />
          <Route path='/projects/:projectName' element={<Projects />} />
          <Route path='/experience' element={<Experience />} />
        </Route>
      </Routes>
    </div>
  );
}
